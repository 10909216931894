import { AppModule } from '@/modules/modules'
import { EeveeRoute, EeveeRoutes } from '@/modules/eevee/routes'

export const EeveeModule: AppModule = {
  name: 'eevee',
  routes: EeveeRoutes,
  getSidebarModule(router) {
    return {
      title: 'Eevee',
      children: [
        { label: 'Voices', routePath: router.resolve({ name: EeveeRoute.Voices }).path, icon: 'mic' },
        {
          label: 'Voices (cloned)',
          routePath: router.resolve({ name: EeveeRoute.VoicesCloned }).path,
          icon: 'cyclone',
        },
      ],
    }
  },
}

EeveeModule.routes.meta = { ...(EeveeModule.routes.meta || {}), module: EeveeModule.name }

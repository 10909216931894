
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { AppModules } from '@/modules/modules'

export interface SidebarItem {
  label: string
  routePath: string
  icon: string
}

export interface SidebarModule {
  title: string
  children: SidebarItem[]
}

export default defineComponent({
  name: 'AppSidebar',
  data() {
    const router = useRouter()

    return {
      /*
       * Here we fetch all sidebar items (each module registers its own).
       */
      sidebarModules: AppModules.map((module) => module.getSidebarModule(router)),
    }
  },
  methods: {
    isRouteActive(item: SidebarItem) {
      return this.$route.path.startsWith(item.routePath)
    },
  },
})

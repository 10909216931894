
import Logo from '@/components/UI/Logo.vue'
import { defineComponent, reactive } from 'vue'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormLoading,
} from '@/utils/form-state'
import { login } from '@/api/auth.api'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import useGlobalStore from '@/store/global-store'
import { useRouter } from 'vue-router'
import { AppRoute } from '@/router'

export default defineComponent({
  components: { FormStateAlert, Logo },
  setup() {
    const data = reactive({
      login: '',
      password: '',
      formState: FORM_INITIAL_STATE as FormState,
    })

    const { setApiKey } = useGlobalStore()
    const router = useRouter()

    const loginAction = async () => {
      try {
        data.formState = FORM_LOADING_STATE

        const response = await login({ login: data.login, password: data.password })

        setApiKey(response.adminApiKey)
        await router.push({ name: AppRoute.Admin })
        data.formState = formSuccess('Successfully logged to your account.')
      } catch (e) {
        data.formState = formError(String(e))
      }
    }

    return {
      data,
      loginAction,
      isFormLoading,
    }
  },
})

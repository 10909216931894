
import { defineComponent } from 'vue'
import AppSidebar from '@/components/UI/AppSidebar.vue'
import AppHeader from '@/components/UI/AppHeader.vue'

export default defineComponent({
  name: 'AdminLayout',
  components: {
    AppHeader,
    AppSidebar,
  },
})

import { reactive, watch } from 'vue'

const API_KEY_LS = 'admin-api-key'

interface GlobalConfig {
  statsGridSize: number
}

const state = reactive({
  apiKey: localStorage.getItem('admin-api-key'),
  config: loadGlobalConfig(),
})

watch(state.config, () => {
  saveGlobalConfig()
})

function loadGlobalConfig(): GlobalConfig {
  const defaultCfg: GlobalConfig = {
    statsGridSize: 2,
  }

  const configJSON = localStorage.getItem('global-config')
  if (configJSON) {
    try {
      return JSON.parse(configJSON)
    } catch (error) {
      return defaultCfg
    }
  }
  return defaultCfg
}

function saveGlobalConfig() {
  localStorage.setItem('global-config', JSON.stringify(state.config))
}

export default function useGlobalStore() {
  return {
    state,
    config: state.config,
    isLogged(): boolean {
      return Boolean(state.apiKey)
    },
    getApiKey(): string | null {
      return state.apiKey
    },
    setApiKey(apiKey: string): void {
      state.apiKey = apiKey
      localStorage.setItem(API_KEY_LS, apiKey)
    },
    clearApiKey(): void {
      state.apiKey = null
      localStorage.removeItem(API_KEY_LS)
    },
  }
}

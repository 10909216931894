import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8fbc9c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-header" }
const _hoisted_2 = { class: "app-header__menu" }
const _hoisted_3 = { class: "settings" }
const _hoisted_4 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_Logo),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.AppModules, (module, idx) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
            (module.appHeaderComponent && _ctx.activeModule === module.name)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(module.appHeaderComponent), { key: 0 }))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createVNode(_component_va_button, {
          class: "app-header__settings-button",
          color: "secondary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.data.showSettingsModal = !_ctx.data.showSettingsModal))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_va_icon, { name: "settings" })
          ]),
          _: 1
        }),
        _createVNode(_component_va_button, {
          class: "app-header__logout-button",
          color: "danger",
          onClick: _ctx.logout
        }, {
          default: _withCtx(() => [
            _createTextVNode("Logout")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_va_modal, {
      modelValue: _ctx.data.showSettingsModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.showSettingsModal) = $event)),
      title: "Global settings",
      "hide-default-actions": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_va_select, {
            class: "mb-3",
            bordered: "",
            label: "Statistics grid size",
            modelValue: _ctx.globalStore.config.statsGridSize,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.globalStore.config.statsGridSize) = $event)),
            options: [1, 2, 3, 4]
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_va_button, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.data.showSettingsModal = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save")
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
import ModuleRoot from '@/components/ModuleRoot.vue'
import { RouteRecordRaw } from 'vue-router'

export enum EeveeRoute {
  Root = 'EeveeRoot',
  Voices = 'EeveeVoices',
  EditVoice = 'EeveeEditVoice',
  AddVoice = 'EeveeAddVoice',
  VoicesCloned = 'EeveeVoicesCloned',
}

export const EeveeRoutes: RouteRecordRaw = {
  path: 'eevee',
  name: EeveeRoute.Root,
  component: ModuleRoot,
  redirect: { name: EeveeRoute.Voices },
  children: [
    {
      path: 'voices',
      name: EeveeRoute.Voices,
      component: () => import(/* webpackChunkName: "eevee-voices-chunk" */ './views/Voices.vue'),
    },
    {
      path: 'cloned-voices',
      name: EeveeRoute.VoicesCloned,
      component: () => import(/* webpackChunkName: "eevee-voices-chunk" */ './views/VoicesCloned.vue'),
    },
    {
      path: 'voices/edit/:id',
      name: EeveeRoute.EditVoice,
      component: () => import(/* webpackChunkName: "eevee-voices-chunk" */ './views/Voice.vue'),
    },
    {
      path: 'voices/add/:id',
      name: EeveeRoute.AddVoice,
      component: () => import(/* webpackChunkName: "eevee-voices-chunk" */ './views/Voice.vue'),
    },
    {
      path: '/:catchAll(.*)',
      redirect: { name: EeveeRoute.Voices },
    },
  ],
}

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Page404Layout from '@/layout/Page404Layout.vue'
import Auth from '@/views/Auth.vue'
import AdminLayout from '@/layout/AdminLayout.vue'
import useGlobalStore from '@/store/global-store'
import { TechdemoRoute } from '@/modules/techdemo/routes'
import { AppModules } from '@/modules/modules'

export enum AppRoute {
  Login = 'Login',
  Admin = 'Admin',
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/admin',
  },
  {
    path: '/login',
    name: AppRoute.Login,
    component: Auth,
    beforeEnter(to, from, next) {
      const { isLogged } = useGlobalStore()

      if (isLogged()) {
        next({ name: AppRoute.Admin })
      } else {
        next()
      }
    },
  },
  {
    path: '/admin',
    redirect: { name: TechdemoRoute.Users },
    name: AppRoute.Admin,
    component: AdminLayout,
    beforeEnter(to, from, next) {
      const { isLogged } = useGlobalStore()

      if (isLogged()) {
        next()
      } else {
        next({ name: AppRoute.Login })
      }
    },
    children: [
      /*
       * Adds routes of all registered modules.
       * Routes of each module should be prefixed (e.g. "techdemo/*").
       */
      ...AppModules.map((module) => module.routes),
      {
        path: '/:catchAll(.*)',
        redirect: { name: TechdemoRoute.Users },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    component: Page404Layout,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router

export type FormStateInitial = {
  __tag: 'initial'
}

export const FORM_INITIAL_STATE: FormStateInitial = {
  __tag: 'initial',
}

export type FormStateLoading = {
  __tag: 'loading'
}

export const FORM_LOADING_STATE: FormStateLoading = {
  __tag: 'loading',
}

export type FormStateSuccess = {
  __tag: 'success'
  message: string
}

export type FormStateError = {
  __tag: 'error'
  message: string
  errors: string[]
}

export type FormState = FormStateInitial | FormStateLoading | FormStateSuccess | FormStateError

export function formSuccess(message: string): FormStateSuccess {
  return {
    __tag: 'success',
    message,
  }
}

export function formError(message: string, errors: string[] = []): FormStateError {
  return {
    __tag: 'error',
    message,
    errors,
  }
}

export function isFormInitial(state: FormState): boolean {
  return state.__tag === 'initial'
}

export function isFormLoading(state: FormState): boolean {
  return state.__tag === 'loading'
}

export function isFormSuccess(state: FormState): state is FormStateSuccess {
  return state.__tag === 'success'
}

export function isFormErrored(state: FormState): state is FormStateError {
  return state.__tag === 'error'
}

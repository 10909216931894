
import { defineComponent, PropType } from 'vue'
import { isFormLoading, isFormInitial, isFormSuccess, isFormErrored, FormState } from '@/utils/form-state'
import DataLoader from '@/components/UI/DataLoader.vue'

export default defineComponent({
  name: 'FormStateAlert',
  components: { DataLoader },
  props: {
    formState: {
      type: Object as PropType<FormState>,
      required: true,
    },
  },
  setup() {
    return { isFormLoading, isFormInitial, isFormSuccess, isFormErrored }
  },
})

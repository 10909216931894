import { AppModule } from '@/modules/modules'
import { SlowpokeRoute, SlowpokeRoutes } from '@/modules/slowpoke/routes'

export const SlowpokeModule: AppModule = {
  name: 'slowpoke',
  routes: SlowpokeRoutes,
  getSidebarModule(router) {
    return {
      title: 'Slowpoke',
      children: [
        { label: 'Clients', routePath: router.resolve({ name: SlowpokeRoute.Clients }).path, icon: 'group' },
        { label: 'Voices', routePath: router.resolve({ name: SlowpokeRoute.Voices }).path, icon: 'mic' },
        {
          label: 'Cloned Voices',
          routePath: router.resolve({ name: SlowpokeRoute.ClonedVoices }).path,
          icon: 'cyclone',
        },
        { label: 'Promo codes', routePath: router.resolve({ name: SlowpokeRoute.PromoCodes }).path, icon: 'redeem' },
        {
          label: 'Statistics',
          routePath: router.resolve({ name: SlowpokeRoute.Statistics }).path,
          icon: 'trending_up',
        },
        {
          label: 'Surveys',
          routePath: router.resolve({ name: SlowpokeRoute.Surveys }).path,
          icon: 'assignment',
        },
      ],
    }
  },
}

SlowpokeModule.routes.meta = { ...(SlowpokeModule.routes.meta || {}), module: SlowpokeModule.name }

export const VuesticConfig = {
  colors: {
    variables: {
      primary: '#3a7bd5',
      success: '#11c26d',
      warning: '#FFCD17',
      danger: '#ff4c52',

      black: '#000000',
      shark: '#1D2325',
      'outer-space': '#37474f',
      slate: '#76838F',
      mystic: '#e4eaec',
      'athens-gray': '#f3f4f8',
      'white-lilac': '#F8F9FD',
      'almost-white': '#fafafa',
      white: '#ffffff',
    },
  },
  components: {
    VaButton: {
      textColor: 'white',
    },
  },
} as const

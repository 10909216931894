import { RouteRecordRaw } from 'vue-router'
import ModuleRoot from '@/components/ModuleRoot.vue'

export enum TechdemoRoute {
  Root = 'TechdemoRoot',
  Users = 'TechdemoUsers',
  CreateUser = 'TechdemoCreateUser',
  EditUser = 'TechdemoEditUser',
  AccessTemplates = 'TechdemoAccessTemplates',
  CreateAccessTemplate = 'TechdemoCreateAccessTemplate',
  EditAccessTemplate = 'TechdemoEditAccessTemplate',
}

export const TechdemoRoutes: RouteRecordRaw = {
  path: 'techdemo',
  name: TechdemoRoute.Root,
  component: ModuleRoot,
  redirect: { name: TechdemoRoute.Users },
  children: [
    {
      path: 'users/edit/:id',
      name: TechdemoRoute.EditUser,
      component: () => import(/* webpackChunkName: "techdemo-users-chunk" */ './views/User.vue'),
    },
    {
      path: 'users/create/:id?',
      name: TechdemoRoute.CreateUser,
      component: () => import(/* webpackChunkName: "techdemo-users-chunk" */ './views/User.vue'),
    },
    {
      path: 'users',
      name: TechdemoRoute.Users,
      component: () => import(/* webpackChunkName: "techdemo-users-chunk" */ './views/Users.vue'),
    },
    {
      path: 'access-templates/edit/:id',
      name: TechdemoRoute.EditAccessTemplate,
      component: () => import(/* webpackChunkName: "techdemo-access-templates-chunk" */ './views/AccessTemplate.vue'),
    },
    {
      path: 'access-templates/create/:id?',
      name: TechdemoRoute.CreateAccessTemplate,
      component: () => import(/* webpackChunkName: "techdemo-access-templates-chunk" */ './views/AccessTemplate.vue'),
    },
    {
      path: 'access-templates',
      name: TechdemoRoute.AccessTemplates,
      component: () => import(/* webpackChunkName: "techdemo-access-templates-chunk" */ './views/AccessTemplates.vue'),
    },
    {
      path: '/:catchAll(.*)',
      redirect: { name: TechdemoRoute.Users },
    },
  ],
}


import { computed, defineComponent, reactive } from 'vue'
import Logo from '@/components/UI/Logo.vue'
import useGlobalStore from '@/store/global-store'
import { useRoute, useRouter } from 'vue-router'
import { AppRoute } from '@/router'
import { AppModules } from '@/modules/modules'

/*
 * @TODO Currently the header displays header component of each AppModule. It should instead only display an active module's component.
 */
export default defineComponent({
  name: 'AppHeader',
  components: { Logo },
  setup() {
    const data = reactive({
      showSettingsModal: false,
    })

    const { clearApiKey } = useGlobalStore()
    const router = useRouter()
    const route = useRoute()
    const globalStore = useGlobalStore()

    const logout = () => {
      clearApiKey()
      router.push({ name: AppRoute.Login })
    }

    const activeModule = computed(() => route.meta.module)

    return { data, globalStore, logout, AppModules, activeModule }
  },
})

import ModuleRoot from '@/components/ModuleRoot.vue'
import { RouteRecordRaw } from 'vue-router'

export enum SlowpokeRoute {
  Root = 'SlowpokeRoot',
  Voices = 'SlowpokeVoices',
  EditVoice = 'SlowpokeEditVoice',
  AddVoice = 'SlowpokeAddVoice',
  ClonedVoices = 'SlowpokeClonedVoices',
  EditClonedVoice = 'SlowpokeEditClonedVoice',
  PromoCodes = 'SlowpokePromoCodes',
  AddPromoCode = 'SlowpokeAddPromoCode',
  Clients = 'SlowpokeClients',
  EditClient = 'SlowpokeEditClient',
  EditApiKey = 'SlowpokeEditApiKey',
  Statistics = 'SlowpokeStatistics',
  Surveys = 'SlowpokeSurveys',
}

export const SlowpokeRoutes: RouteRecordRaw = {
  path: 'slowpoke',
  name: SlowpokeRoute.Root,
  component: ModuleRoot,
  redirect: { name: SlowpokeRoute.Voices },
  children: [
    {
      path: 'voices',
      name: SlowpokeRoute.Voices,
      component: () => import(/* webpackChunkName: "slowpoke-voices-chunk" */ './views/Voices.vue'),
    },
    {
      path: 'voices/edit/:id',
      name: SlowpokeRoute.EditVoice,
      component: () => import(/* webpackChunkName: "slowpoke-voices-chunk" */ './views/Voice.vue'),
    },
    {
      path: 'voices/add/:id',
      name: SlowpokeRoute.AddVoice,
      component: () => import(/* webpackChunkName: "slowpoke-voices-chunk" */ './views/Voice.vue'),
    },
    {
      path: 'cloned-voices',
      name: SlowpokeRoute.ClonedVoices,
      component: () => import(/* webpackChunkName: "slowpoke-voices-chunk" */ './views/ClonedVoices.vue'),
    },
    {
      path: 'cloned-voices/edit/:id',
      name: SlowpokeRoute.EditClonedVoice,
      component: () => import(/* webpackChunkName: "slowpoke-voices-chunk" */ './views/ClonedVoice.vue'),
    },
    {
      path: 'clients',
      name: SlowpokeRoute.Clients,
      component: () => import(/* webpackChunkName: "slowpoke-clients-chunk" */ './views/Clients.vue'),
    },
    {
      path: 'clients/edit/:id',
      name: SlowpokeRoute.EditClient,
      component: () => import(/* webpackChunkName: "slowpoke-clients-chunk" */ './views/Client.vue'),
    },
    {
      path: 'promo-codes',
      name: SlowpokeRoute.PromoCodes,
      component: () => import(/* webpackChunkName: "slowpoke-promo-codes-chunk" */ './views/PromoCodes.vue'),
    },
    {
      path: 'promo-codes/add',
      name: SlowpokeRoute.AddPromoCode,
      component: () => import(/* webpackChunkName: "slowpoke-promo-codes-chunk" */ './views/PromoCode.vue'),
    },
    {
      path: 'api-keys/edit/:id',
      name: SlowpokeRoute.EditApiKey,
      component: () => import(/* webpackChunkName: "slowpoke-clients-chunk" */ './views/ApiKey.vue'),
    },
    {
      path: 'stats',
      name: SlowpokeRoute.Statistics,
      component: () => import(/* webpackChunkName: "slowpoke-stats-chunk" */ './views/Statistics.vue'),
    },
    {
      path: 'surveys',
      name: SlowpokeRoute.Surveys,
      component: () => import(/* webpackChunkName: "slowpoke-surveys-chunk" */ './views/Surveys.vue'),
    },
    {
      path: '/:catchAll(.*)',
      redirect: { name: SlowpokeRoute.Voices },
    },
  ],
}

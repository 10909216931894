import ModuleRoot from '@/components/ModuleRoot.vue'
import { RouteRecordRaw } from 'vue-router'

export enum CISORoute {
  Root = 'CISORoot',
  Voices = 'CISOVoices',
  EditVoice = 'CISOEditVoice',
  CreateVoice = 'CISOCreateVoice',
  VoicePreviews = 'CISOVoicePreviews',
  AssetManager = 'CISOAssetManager',
  AssetImport = 'CISOAssetImport',
  AssetExport = 'CISOAssetExport',
  AssetMigrationStatus = 'CISOAssetMigrationStatus',
  AssetHome = 'CISOAssetManagerHome',
  EditAssetCollection = 'CISOEditAssetCollection',
  CreateAssetCollection = 'CISOCreateAssetCollection',
  EditAssetActor = 'CISOEditAssetActor',
  AssetActorVariants = 'CISOAssetActorVariants',
  CreateAssetActor = 'CISOCreateAssetActor',
  EditAssetBackground = 'CISOEditAssetBackground',
  CreateAssetBackground = 'CISOCreateAssetBackground',
}

export const CISORoutes: RouteRecordRaw = {
  path: 'ciso',
  name: CISORoute.Root,
  component: ModuleRoot,
  redirect: { name: CISORoute.Voices },
  children: [
    {
      path: 'voices',
      name: CISORoute.Voices,
      component: () => import(/* webpackChunkName: "ciso-voices-chunk" */ './views/Voices.vue'),
    },
    {
      path: 'voice-previews',
      name: CISORoute.VoicePreviews,
      component: () => import(/* webpackChunkName: "ciso-voices-chunk" */ './views/VoicePreviews.vue'),
    },
    {
      path: 'voices/edit/:id',
      name: CISORoute.EditVoice,
      component: () => import(/* webpackChunkName: "ciso-voices-chunk" */ './views/Voice.vue'),
    },
    {
      path: 'voices/create/:id?',
      name: CISORoute.CreateVoice,
      component: () => import(/* webpackChunkName: "ciso-voices-chunk" */ './views/Voice.vue'),
    },
    {
      path: '/:catchAll(.*)',
      redirect: { name: CISORoute.Voices },
    },
    {
      path: 'asset-manager',
      name: CISORoute.AssetManager,
      redirect: { name: CISORoute.AssetHome },
      component: ModuleRoot,
      children: [
        {
          path: 'migration',
          name: CISORoute.AssetMigrationStatus,
          component: () =>
            import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetMigrationStatus.vue'),
        },
        {
          path: 'import',
          name: CISORoute.AssetImport,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetImport.vue'),
        },
        {
          path: 'export',
          name: CISORoute.AssetExport,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetExport.vue'),
        },
        {
          path: 'home',
          name: CISORoute.AssetHome,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetManagerHome.vue'),
        },
        {
          path: 'collections/edit/:collectionName?',
          name: CISORoute.EditAssetCollection,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetCollection.vue'),
        },
        {
          path: 'collections/create/:collectionName?',
          name: CISORoute.CreateAssetCollection,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetCollection.vue'),
        },
        {
          path: 'collections/:collectionName/actors/edit/:actorName?',
          name: CISORoute.EditAssetActor,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetActor.vue'),
        },
        {
          path: 'collections/:collectionName/actors/variants/:actorName?',
          name: CISORoute.AssetActorVariants,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetActorVariants.vue'),
        },
        {
          path: 'collections/:collectionName/actors/create/:actorName?',
          name: CISORoute.CreateAssetActor,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetActor.vue'),
        },
        {
          path: 'collections/:collectionName/backgrounds/edit/:backgroundName?',
          name: CISORoute.EditAssetBackground,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetBackground.vue'),
        },
        {
          path: 'collections/:collectionName/backgrounds/create/:backgroundName?',
          name: CISORoute.CreateAssetBackground,
          component: () => import(/* webpackChunkName: "ciso-asset-manager-chunk" */ './views/AssetBackground.vue'),
        },
      ],
    },
  ],
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createVuestic } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import { VuesticConfig } from '@/vuestic.config'
import './assets/override.css'
import { Chart } from 'chart.js'

Chart.defaults.backgroundColor = '#3a7bd5'

createApp(App)
  .use(router)
  .use(createVuestic({ config: VuesticConfig }))
  .mount('#app')

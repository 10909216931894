<template>
  <div class="page-404">
    <h1 class="va-h1">Error 404</h1>
    <div class="message">
      <p>We couldn't find the page you're looking for.</p>
    </div>
    <va-button to="/">Go Home</va-button>
  </div>
</template>

<script>
export default {
  name: 'Page404Layout',
}
</script>

<style lang="scss">
.page-404 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.message {
  padding: 30px 0;
}
</style>

<template>
  <h1 class="logo">charactr</h1>
</template>

<style scoped lang="scss">
.logo {
  color: var(--va-black);
  font-weight: 700;
  font-size: 2rem;
  margin: 0;
}
</style>

import axios, { AxiosError } from 'axios'

export class ApiError extends Error {
  errors: string[]

  constructor(message: string, errors?: string[]) {
    super(message)
    this.errors = errors || []
  }
}

type AxiosErrorData = {
  status?: string
  code?: number
  msg?: string
  message?: string
  errors?: string[]
}

export function apiErrorCatcher(e: AxiosError<AxiosErrorData>): never {
  const status = e?.response?.data?.status || e?.response?.data?.code
  const msg = e?.response?.data?.msg || e?.response?.data?.message

  if (status && msg) {
    throw new ApiError(`${msg} (Status: ${status})`, e?.response?.data?.errors)
  }

  throw e
}

export function addAxiosGlobalHeader(apiUrl: string, key: string, value: string) {
  return axios.interceptors.request.use(function (config) {
    if (config && config.headers && config.url?.startsWith(apiUrl)) {
      config.headers[key] = value
    }

    return config
  })
}

import { Router, RouteRecordRaw } from 'vue-router'
import { SidebarModule } from '@/components/UI/AppSidebar.vue'
import { TechdemoModule } from '@/modules/techdemo/module'
import { CISOModule } from '@/modules/ciso/module'
import { Component } from 'vue'
import { SlowpokeModule } from '@/modules/slowpoke/module'
import { EeveeModule } from '@/modules/eevee/module'

export interface AppModule {
  name: string
  /*
   * Routes exposed by particular module.
   * Should be prefixed (e.g. techdemo/*)
   */
  routes: RouteRecordRaw
  /*
   * Returns list of items that will be added to the admin menu.
   * Look at the type definition for more details.
   */
  getSidebarModule: (router: Router) => SidebarModule
  /*
   * Allows module to display component in the AppHeader (left to "Logout" button)
   */
  appHeaderComponent?: Component
}

export const AppModules: AppModule[] = [CISOModule, EeveeModule, SlowpokeModule, TechdemoModule]


import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DataLoader',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
})

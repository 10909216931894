import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b002b17a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_2 = {
  key: 0,
  class: "errors-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_va_alert = _resolveComponent("va-alert")!

  return (!_ctx.isFormInitial(_ctx.formState))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DataLoader, {
          "is-loading": _ctx.isFormLoading(_ctx.formState)
        }, null, 8, ["is-loading"]),
        (_ctx.isFormErrored(_ctx.formState))
          ? (_openBlock(), _createBlock(_component_va_alert, {
              key: 0,
              color: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formState.message) + " ", 1),
                (_ctx.formState.errors.length)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formState.errors, (error) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: "errors-list__item",
                          key: error
                        }, _toDisplayString(error), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.isFormSuccess(_ctx.formState))
          ? (_openBlock(), _createBlock(_component_va_alert, {
              key: 1,
              color: "success"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formState.message), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
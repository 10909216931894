import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c1f7db2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "sidebar__modules" }
const _hoisted_3 = { class: "sidebar__module-title" }
const _hoisted_4 = { class: "sidebar__items" }
const _hoisted_5 = ["href", "onClick"]
const _hoisted_6 = { class: "sidebar__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarModules, (module) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "sidebar__module",
          key: module.title
        }, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(module.title), 1),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(module.children, (item) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: item.label,
                to: item.routePath,
                custom: ""
              }, {
                default: _withCtx(({ href, navigate }) => [
                  _createElementVNode("li", {
                    class: _normalizeClass(['sidebar__item', { active: _ctx.isRouteActive(item) }])
                  }, [
                    _createElementVNode("a", {
                      class: _normalizeClass(['sidebar__link', { active: _ctx.isRouteActive(item) }]),
                      href: href,
                      onClick: navigate
                    }, [
                      _createVNode(_component_va_icon, {
                        name: item.icon
                      }, null, 8, ["name"]),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(item.label), 1)
                    ], 10, _hoisted_5)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}
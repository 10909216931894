import { AppModule } from '@/modules/modules'
import { CISORoute, CISORoutes } from '@/modules/ciso/routes'

export const CISOModule: AppModule = {
  name: 'ciso',
  routes: CISORoutes,
  getSidebarModule(router) {
    return {
      title: 'CISO',
      children: [
        { label: 'Voices', routePath: router.resolve({ name: CISORoute.Voices }).path, icon: 'mic' },
        { label: 'Voice previews', routePath: router.resolve({ name: CISORoute.VoicePreviews }).path, icon: 'mic' },
        {
          label: 'Asset manager',
          routePath: router.resolve({ name: CISORoute.AssetManager }).path,
          icon: 'collections',
        },
      ],
    }
  },
}

CISOModule.routes.meta = { ...(CISOModule.routes.meta || {}), module: CISOModule.name }

import { AppModule } from '@/modules/modules'
import { TechdemoRoute, TechdemoRoutes } from '@/modules/techdemo/routes'

export const TechdemoModule: AppModule = {
  name: 'techdemo',
  routes: TechdemoRoutes,
  getSidebarModule(router) {
    return {
      title: 'Techdemo',
      children: [
        { label: 'Users', routePath: router.resolve({ name: TechdemoRoute.Users }).path, icon: 'people' },
        {
          label: 'Access templates',
          routePath: router.resolve({ name: TechdemoRoute.AccessTemplates }).path,
          icon: 'description',
        },
      ],
    }
  },
}

TechdemoModule.routes.meta = { ...(TechdemoModule.routes.meta || {}), module: TechdemoModule.name }

export function getUrl(base: string, paths: string | Array<string>): string {
  base = base.replace(/\/+$/, '')

  if (typeof paths === 'string') {
    paths = [paths]
  }

  paths = paths.filter((path) => Boolean(path)).map((path) => path.replace(/^\/+/, ''))

  return `${base}/${paths.join('/')}`
}

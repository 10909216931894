import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { AuthInput, AuthResponseData } from '@/model/auth.model'
import { techdemoApiUrl } from '@/modules/techdemo/api/_shared'

export async function login(input: AuthInput): Promise<AuthResponseData> {
  return (
    axios
      /*
       * For simplicity reasons, CharactrAdmin uses TechdemoAPI for authentication.
       * At some point we might want to develop its own auth service.
       */
      .post(techdemoApiUrl('admin/auth/login'), input)
      .then((r) => r.data)
      .catch((e) => {
        if (e?.response?.status === 401) {
          throw new Error('Given login or password are invalid.')
        }

        throw e
      })
      .catch(apiErrorCatcher)
  )
}
